import { Fragment } from 'react';
import { useRouter } from 'next/router';
import cx from 'classnames';
import { useTranslation } from 'next-i18next';
import { TabList, TabListButton, TabPanel, TabPanels, Tabs } from '@dx-ui/osc-tabs';
import type { GetHotelHomePageQuery } from '@dx-ui/queries-dx-property-ui';

import type { TTabbedOverviewContent } from './TabbedOverviewContent';
import TabbedOverviewContent from './TabbedOverviewContent';

import { useTheme } from '../../hooks/use-theme';
import DiningButtons from '../dining-buttons/DiningButtons';
import { BrandLink } from '../brand-buttons/brand-buttons';
import { generateUrl } from '../../helpers/utils/client';

export type TTab = TTabbedOverviewContent | null;

export type TTabbedOverview = {
  diningTab: TTab;
  golfTab: TTab;
  spaTab: TTab;
  onSiteRestaurants?: NonNullable<
    NonNullable<GetHotelHomePageQuery['hotel']>['restaurantOverview']
  >['restaurants'];
  traits?: string[];
};

const TabbedOverview = ({ diningTab, golfTab, spaTab, onSiteRestaurants }: TTabbedOverview) => {
  const { t } = useTranslation(['common', 'dining']);
  const { theme } = useTheme();
  const { relativeUrl } = generateUrl(useRouter());
  const hasOnlyOneRestaurant = onSiteRestaurants?.length === 1;

  const hasOnlyOneRestaurantAndNoGolfOrSpa = hasOnlyOneRestaurant && !spaTab && !golfTab;
  const tabs: TTabbedOverviewContent[] = [diningTab, spaTab, golfTab].filter((tab) => tab !== null);

  return (
    <>
      {hasOnlyOneRestaurantAndNoGolfOrSpa ? (
        <h2
          className={cx('heading-2xl lg:heading-3xl pb-8 pt-10 text-center', {
            'accent-heading': theme.accent?.heading,
          })}
        >
          {t('common:diningAndDrinks')}
        </h2>
      ) : null}
      {tabs?.length ? (
        <Tabs
          className={cx('mx-auto max-w-[1440px] px-4 py-12 md:px-8 lg:px-10', {
            'pt-0': hasOnlyOneRestaurantAndNoGolfOrSpa,
          })}
          changeWithArrow
        >
          <TabList
            data-testid="tabbed-tablist"
            className={cx('text-xl font-bold', {
              hidden: tabs?.length <= 1,
            })}
          >
            {tabs.map((tab) => {
              const tabName = t(`common:${tab.id}`);
              return (
                <Fragment key={tab.id}>
                  <TabListButton
                    data-testid="tabbed-tablistbutton"
                    id={tabName}
                    activeClassName={cx('border-b-border', {
                      '!accent-border': theme.accent?.border,
                      '!accent-heading hover:!accent-heading focus:!accent-heading':
                        theme.accent?.heading,
                    })}
                    inactiveClassName={cx({
                      '!text-heading hover:!accent-heading focus:!accent-heading':
                        theme.accent?.heading,
                    })}
                  >
                    {tabName}
                  </TabListButton>
                </Fragment>
              );
            })}
          </TabList>
          <TabPanels>
            {tabs.map((tab) => {
              const { id } = tab;
              return (
                <TabPanel
                  className="pt-8"
                  id={t(`common:${id}`)}
                  key={id}
                  data-testid={`tabbed-${id}-content`}
                >
                  <TabbedOverviewContent
                    {...tab}
                    isHeadlineFont={!hasOnlyOneRestaurantAndNoGolfOrSpa}
                  >
                    <div className="mt-4 flex w-full">
                      {tab.id === 'dining' ? (
                        <DiningButtons
                          className="flex-row"
                          onSiteRestaurants={onSiteRestaurants || []}
                        />
                      ) : (
                        <BrandLink
                          url={`${relativeUrl}/${tab.id}/`}
                          label={t(`common:ctaText.${tab.id}`)}
                        />
                      )}
                    </div>
                  </TabbedOverviewContent>
                </TabPanel>
              );
            })}
          </TabPanels>
        </Tabs>
      ) : null}
    </>
  );
};

export default TabbedOverview;
